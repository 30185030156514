








































































































































import PreferredLanguageFilter from '@/shared/components/PreferredLanguageFilter.vue';
import { genderOptions } from '@/shared/gender';
import PreferredCommunication from '@/shared/components/PreferredCommunication.vue';
import { InlineLoader, Alert } from '@/shared';
import { defineComponent, ref } from '@vue/composition-api';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';
import { VuetifySelectOption } from '@conversa/bedazzled/src/models/select-options.interface';
import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';
import { PatientLanguage } from '@conversa/bedazzled/src/models/patient-language.type';
import { CommunicationChannel } from '@conversa/bedazzled/src/models/communication-channel.type';
import DatePicker from '@/shared/components/DatePicker.vue';
import DialogTitle from '@/dialog/DialogTitle.vue';
import sink from '@/sink';

interface Patient {
  userId: number;
  clinicPatientCode: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  preferredLanguage: PatientLanguage;
  dateOfBirth: string;
  gender: string;
  preferredCommunicationChannel: CommunicationChannel;
}

export default defineComponent<{
  alertMessage: string;
  canSeeLanguageSelector: boolean;
  demographicCapabilities: Demographics;
  inputs: Patient;
  isLoadingPatient: boolean;
  loadingProviders: boolean;
  providers: VuetifySelectOption[];
  title: string;
}>({
  props: [
    'alertMessage',
    'canSeeLanguageSelector',
    'languageItems',
    'demographicCapabilities',
    'inputs',
    'isLoadingPatient',
    'loadingProviders',
    'providers',
    'title',
  ],
  emits: ['cancel', 'save', 'comm-values-updated', 'on-change'],
  components: {
    Alert,
    DatePicker,
    InlineLoader,
    PreferredCommunication,
    PreferredLanguageFilter,
    DialogTitle,
  },
  setup(_, context) {
    const isFormValid = ref(false);

    return {
      sms: sink.select('capabilities.features.sms'),
      isFormValid,
      minDate: format(subYears(new Date(), 130), 'yyyy-MM-dd'),
      maxDate: format(new Date(), 'yyyy-MM-dd'),
      genderOptions,
      stateOptions: sink.select('patients.detail.state.select-options'),
      cancel: () => context.emit('cancel'),
      save: () => context.emit('save'),
      commValuesUpdated: values => context.emit('comm-values-updated', values),
      onChange: ({ key, value }) => context.emit('on-change', { key, value }),
    };
  },
});
