



















import { patientsDetailModule } from '@/patients/detail/+state/module';
import { PatientUpdatePayload } from '@/patients/models/patient-update-payload.interface';
import { defaultAlertMessage } from '@/shared';
import sink from '@/sink';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { PreferredCommunicationEventPayload } from '@conversa/bedazzled/src/inputs/PreferredCommunication/preferred-communication-event.interface';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  reactive,
  ref,
} from '@vue/composition-api';
import { ofType } from 'ts-action-operators';
import {
  EditPatientDialogSave,
  EditPatientFailed,
  EditPatientInvalid,
} from './+state/events';
import EditPatientDialogBase from './EditPatientDialogBase.vue';

/*
 * When we get API validation errors the API sends back a field and a message, this
 * maps the programmatic field name from the API to a name that matches the UI
 */
const validationFieldMap = {
  userId: 'Provider',
  clinicPatientCode: 'Patient ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  mobilePhone: 'Phone',
  email: 'Email',
  preferredLanguage: 'Preferred Language',
  dateOfBirth: 'Date of Birth',
  gender: 'Gender',
};

export default defineComponent({
  components: {
    EditPatientDialogBase,
  },
  setup(_, context) {
    const patientsDetailStore = patientsDetailModule.injectStore();
    const inputs: PatientUpdatePayload = reactive({
      userId: patientsDetailStore.data.user.id,
      clinicPatientCode: patientsDetailStore.data?.clinic_patient_code,
      firstName: patientsDetailStore.data?.first_name,
      lastName: patientsDetailStore.data?.last_name,
      mobilePhone: patientsDetailStore.data?.mobile_phone,
      email: patientsDetailStore.data?.email,
      preferredLanguage: patientsDetailStore.data?.preferred_language || 'en',
      dateOfBirth:
        patientsDetailStore.data?.date_of_birth?.replace(/T.*/, '') || '',
      gender: patientsDetailStore.data?.gender || '',
      preferredCommunicationChannel:
        patientsDetailStore.data?.preferred_communication_channel,
      state: patientsDetailStore.data?.state || '',
    });

    const alertMessage = ref(null);

    const resetAlert = () => {
      alertMessage.value = null;
    };

    const editPatientFailed$ = sink.events$
      .pipe(ofType(EditPatientFailed))
      .subscribe(() => {
        alertMessage.value = defaultAlertMessage;
      });

    const editPatientInvalid$ = sink.events$
      .pipe(ofType(EditPatientInvalid))
      .subscribe(event => {
        if (event.payload.errors?.length) {
          const firstError = event.payload.errors[0];
          alertMessage.value = `${validationFieldMap[firstError.field]} ${
            firstError.message
          }`;
        } else {
          alertMessage.value = defaultAlertMessage;
        }
      });

    onBeforeUnmount(() => {
      editPatientInvalid$.unsubscribe();
      editPatientFailed$.unsubscribe();
    });

    const sms = sink.select('capabilities.features.sms');

    return {
      loadingProviders: sink.select('providers.loading'),
      isLoadingPatient: computed(() => patientsDetailStore.profileLoading),
      languageItems: sink.select('capabilities.enabledLanguages.selectOptions'),
      canSeeLanguageSelector: sink.select(
        'capabilities.enabledLanguages.hasMultiple',
      ),
      providers: sink.select('providers.select-options'),
      demographicCapabilities: sink.select('capabilities.demographics'),
      inputs,
      alertMessage,

      onChange: ({ key, value }) => (inputs[key] = value),
      cancel: () => {
        resetAlert();
        DialogInstance.close();
      },
      save() {
        resetAlert();

        /*
         * Normally the validation would catch when dateOfBirth is empty and carp, but when the demographic settings
         * for the org have dateOfBirth disabled, we want to make sure that we pass back `null` for any blankish values
         */
        let dateOfBirth = inputs.dateOfBirth;
        if (
          !this.demographicCapabilities?.dateOfBirthEnabled &&
          !dateOfBirth?.length
        ) {
          dateOfBirth = null;
        }

        // same for gender
        let gender = inputs.gender;
        if (!this.demographicCapabilities?.genderEnabled && !gender?.length) {
          gender = null;
        }

        // same for state
        let state = inputs.state;
        if (!this.demographicCapabilities?.stateEnabled && !state?.length) {
          state = null;
        }

        const inputsWithHidden = {
          ...inputs,
          preferredCommunicationChannel: !sms.value
            ? 'email'
            : inputs.preferredCommunicationChannel,
          userId: inputs.userId,
          patientId: parseInt(context.root.$route.params.patientId, 10),
          dateOfBirth,
          gender,
          state,
        };

        sink.broadcast(EditPatientDialogSave(inputsWithHidden));
      },

      commValuesUpdated(values: PreferredCommunicationEventPayload) {
        inputs.email = values.email;
        inputs.mobilePhone = values.phone;
        inputs.preferredCommunicationChannel = values.preferred;
      },
    };
  },
});
