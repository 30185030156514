import { render, staticRenderFns } from "./ScheduledChatsTable.vue?vue&type=template&id=c3494e74&scoped=true&"
import script from "./ScheduledChatsTable.vue?vue&type=script&lang=ts&"
export * from "./ScheduledChatsTable.vue?vue&type=script&lang=ts&"
import style0 from "./ScheduledChatsTable.vue?vue&type=style&index=0&id=c3494e74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3494e74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VDataTable})
