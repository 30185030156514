









































































import sink from '@/sink';
import { ActionCard } from '@conversa/bedazzled/src/cards';
import { InlineLoader } from '@/shared';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import DetailCardRow from '../DetailCardRow.vue';
import { PatientProfileMounted } from './+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import EditPatientDialogVue from './EditPatientDialog.vue';
import { VueTelInput } from 'vue-tel-input';

export default defineComponent({
  components: { ActionCard, DetailCardRow, InlineLoader, VueTelInput },
  setup() {
    // when the model is assigned multiple times to the vue-tel-input component
    // the display format fails (shows the country code) added some validations to avoid it
    const details = sink.select('patients.detail.profile');
    const phone = ref('');

    const stopWatching = watch(details, (details, previousDetails) => {
      if (previousDetails.phone !== details.phone) phone.value = details.phone;
    });

    onMounted(() => {
      sink.lazyBroadcast(PatientProfileMounted());
      if (!phone.value) phone.value = details.value.phone;
    });

    onBeforeUnmount(() => {
      stopWatching();
    });

    return {
      edit: () => DialogInstance.open(EditPatientDialogVue),
      isLoadingPatient: sink.select('patients.detail.profile.loading'),
      details,
      phone,
      demographicCapabilities: sink.select('capabilities.demographics'),
      languages: sink.select('capabilities.languages'),
      canEdit: sink.select('capabilities.features.patientEdit'),
      hasMultipleLanguages: sink.select(
        'capabilities.enabledLanguages.hasMultiple',
      ),
    };
  },
});
