




































































import PreferredLanguageFilter from '@/shared/components/PreferredLanguageFilter.vue';
import sink from '@/sink';
import PreferredCommunication from '@/shared/components/PreferredCommunication.vue';
import { PreferredCommunicationEventPayload } from '@conversa/bedazzled/src/inputs/PreferredCommunication/preferred-communication-event.interface';
import { InlineLoader, Alert } from '@/shared';
import { defineComponent, reactive } from '@vue/composition-api';
import { CaregiverCreateUpdatePayload } from '../models';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export default defineComponent<{
  title: string;
  description: string;
  saveButtonCopy: string;
  loadingCopy: string;
}>({
  props: ['title', 'description', 'saveButtonCopy', 'loadingCopy', 'closeable'],
  components: {
    Alert,
    InlineLoader,
    PreferredCommunication,
    PreferredLanguageFilter,
    DialogTitle,
  },
  setup(props, context) {
    const caregiver = sink.select('patients.detail.summary.caregiver').value;

    const inputs: Omit<CaregiverCreateUpdatePayload, 'patientId'> = reactive({
      firstName: null,
      lastName: null,
      mobilePhone: null,
      email: null,
      preferredLanguage: 'en',
      preferredCommunicationChannel: null,
    });
    if (caregiver) {
      (inputs.firstName = caregiver.firstName),
        (inputs.lastName = caregiver.lastName),
        (inputs.mobilePhone = caregiver.mobilePhone),
        (inputs.email = caregiver.email),
        (inputs.preferredLanguage = caregiver.preferredLanguage),
        (inputs.preferredCommunicationChannel =
          caregiver.preferredCommunicationChannel);
    }

    return {
      sms: sink.select('capabilities.features.sms'),
      inputs,
      loading: sink.select('patients.details.summary.caregiver-dialog.loading'),
      error: sink.select(
        'patients.details.summary.caregiver-dialog.error-message',
      ),
      languageItems: sink.select('capabilities.enabledLanguages.selectOptions'),
      canSeeLanguageSelector: sink.select(
        'capabilities.enabledLanguages.hasMultiple',
      ),
      cancel: () => DialogInstance.close(),
      save() {
        context.emit('save', inputs);
      },
      commValuesUpdated(values: PreferredCommunicationEventPayload) {
        inputs.email = values.email;
        inputs.mobilePhone = values.phone;
        inputs.preferredCommunicationChannel = values.preferred;
      },
    };
  },
});
