









import sink from '@/sink';
import { defineComponent, onMounted } from '@vue/composition-api';
import EnrollmentDetailsCard from './EnrollmentDetailsCard.vue';
import {
  EnrollmentChangeStatusClicked,
  EnrollmentEditClicked,
  PatientEnrollmentInformationMounted,
} from './+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog/actions';
import EnrollmentChangeStatusDialogVue from './dialogs/EnrollmentChangeStatusDialog.vue';
import EnrollmentProgramListDialog from './dialogs/EnrollmentProgramListDialog.vue';
import EnrollmentUpdateDialog from './dialogs/EnrollmentUpdateDialog.vue';

export default defineComponent({
  components: { EnrollmentDetailsCard },
  setup() {
    onMounted(sink.lazyBroadcast(PatientEnrollmentInformationMounted()));

    return {
      enrollments: sink.select('patients.detail.summary.enrollments'),
      newEnrollment: () => DialogInstance.open(EnrollmentProgramListDialog),
      editEnrollment: (surveyId, enrollmentId) => {
        sink.broadcast(EnrollmentEditClicked({ id: surveyId, enrollmentId }));
        DialogInstance.open(EnrollmentUpdateDialog);
      },
      editStatus: (surveyId, enrollmentId) => {
        sink.broadcast(
          EnrollmentChangeStatusClicked({ id: surveyId, enrollmentId }),
        );

        DialogInstance.open(EnrollmentChangeStatusDialogVue);
      },
    };
  },
});
