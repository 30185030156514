













import sink from '@/sink';
import ScheduledChatsTable from './ScheduledChatsTable.vue';
import { defineComponent, onMounted } from '@vue/composition-api';
import {
  PatientSessionInformationMounted,
  SessionPageUpdated,
  ViewHistoricUpdated,
} from './+state/events';
import { patientsDetailSummarySessionsModule } from './+state/module';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import PatientResendChatLinkDialog from './PatientResendChatLinkDialog.vue';

export default defineComponent({
  components: { ScheduledChatsTable, PatientResendChatLinkDialog },
  setup() {
    onMounted(sink.lazyBroadcast(PatientSessionInformationMounted()));

    return {
      data: sink.select(
        'patients.detail.summary.sessions.paginated.current-set',
      ),
      pageNumber: sink.select('patients.detail.summary.sessions.page'),
      count: sink.select('patients.detail.summary.sessions.count'),
      activeProgramCount: sink.select(
        'patients.detail.summary.enrollments.active-count',
      ),
      viewHistoric: patientsDetailSummarySessionsModule.injectStore()
        .viewHistoric,
      updatePage: page => sink.broadcast(SessionPageUpdated({ page })),
      resend: () => DialogInstance.open(PatientResendChatLinkDialog),

      updateViewHistoric: ({ checked }) =>
        sink.broadcast(ViewHistoricUpdated({ viewHistoric: checked })),
    };
  },
});
