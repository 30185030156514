












import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import Default from './DefaultDateInput.vue';
import Fallback from './FallbackDateInput.vue';

export default defineComponent({
  components: {
    Default,
    Fallback,
  },
  props: ['name', 'id', 'legend', 'inputValue', 'required', 'min', 'max'],
  setup(props, { emit }) {
    const component = ref(Default);
    const customValidity = ref(null);

    onBeforeMount(() => {
      const test = document.createElement('input');

      try {
        test.type = 'date';
      } catch (e) {
        console.log(e.description);
      }

      if (test.type === 'text') {
        component.value = Fallback;
      }
    });

    const updateValidity = newValue => {
      let validMin = true;
      let validMax = true;
      let valueDate: Date;
      let minDate: Date;
      let maxDate: Date;

      try {
        valueDate = parse(newValue, 'yyyy-MM-dd', new Date());
      } catch (re) {
        console.warn('DateInput new value not parseable', newValue);
      }

      if (!valueDate) {
        return;
      }

      if (props.min?.length) {
        minDate = parse(props.min, 'yyyy-MM-dd', new Date());
        validMin = compareAsc(valueDate, minDate) !== -1; // -1 if valueDate is before minDate
      }

      if (props.max?.length) {
        maxDate = parse(props.max, 'yyyy-MM-dd', new Date());
        validMax = compareAsc(valueDate, maxDate) !== 1; // 1 if valueDate is after maxDate
      }

      if (!validMin) {
        customValidity.value = `Must be on or after ${format(
          minDate,
          'MM/dd/yyyy',
        )}`;
      } else if (!validMax) {
        customValidity.value = `Must be on or before ${format(
          maxDate,
          'MM/dd/yyyy',
        )}`;
      } else {
        customValidity.value = '';
      }
    };

    return {
      component,
      customValidity,
      onChange: newValue => {
        updateValidity(newValue);
        emit('change', newValue);
      },
    };
  },
});
