














import { defineComponent } from '@vue/composition-api';

interface Option {
  value: string;
  content: string;
}

export default defineComponent<{
  required?: boolean;
  id: string;
  inputValue: (string | number)[] | string;
  label: string;
  name: string;
}>({
  props: ['required', 'id', 'inputValue', 'label', 'name'],
});
