














import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: [
    'name',
    'id',
    'placeholder',
    'inputValue',
    'required',
    'pattern',
    'min',
    'max',
    'customValidity',
  ],
  setup(props) {
    const input = ref(null);

    watch(
      () => props.customValidity,
      curr => {
        if (input.value) {
          input.value.setCustomValidity(curr);
        }
      },
    );

    return { input };
  },
});
