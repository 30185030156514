






















import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import Default from './DefaultDateInput.vue';
import Fallback from './FallbackDateInput.vue';
import format from 'date-fns/format';

export default defineComponent({
  components: {
    Default,
    Fallback,
  },
  props: [
    'name',
    'id',
    'legend',
    'inputValue',
    'required',
    'min',
    'max',
    'customValidity',
  ],
  setup(props, { emit }) {
    const yearSelect = ref(null);
    const componentEl = ref(null);
    const selectedDate = ref(null);
    const formattedDate = ref(null);
    const today = new Date();
    const [y, m, d] = (props?.inputValue?.length &&
      props.inputValue.split('-').map(v => parseInt(v, 10))) || [
      today.getUTCFullYear(),
      0,
      0,
    ];

    const month = ref(m - 1);
    const day = ref(d);
    const year = ref(y);

    const months = [
      { value: 0, content: 'January' },
      { value: 1, content: 'February' },
      { value: 2, content: 'March' },
      { value: 3, content: 'April' },
      { value: 4, content: 'May' },
      { value: 5, content: 'June' },
      { value: 6, content: 'July' },
      { value: 7, content: 'August' },
      { value: 8, content: 'September' },
      { value: 9, content: 'October' },
      { value: 10, content: 'November' },
      { value: 11, content: 'December' },
    ];

    const days = computed(() => {
      if (isNaN(year.value) || isNaN(month.value)) {
        return [];
      }

      return Array(getDaysInMonth(new Date(year.value, month.value)))
        .fill(0)
        .map((_, i) => i + 1);
    });

    // Calculate the years for the dropdown. If `min` or `max` isn't specified
    // set the missing option to +/- 150
    const years = computed(() => {
      let start = new Date(props.min).getUTCFullYear();
      let stop = new Date(props.max).getUTCFullYear();

      if (isNaN(start)) {
        start = today.getUTCFullYear() - 150;
      }

      if (isNaN(stop)) {
        stop = today.getUTCFullYear() + 150;
      }

      return Array(stop - start + 1)
        .fill(0)
        .map((_, i) => start + i)
        .reverse();
    });

    watch([month, day, year], (curr: number[]) => {
      const [month, day, year] = curr.map((n: string | number): number => {
        if (typeof n === 'string') {
          return parseInt(n, 10);
        }

        return n;
      });

      if (month === -1 || day === 0 || year === 0) {
        emit('change', '');
        return;
      }

      selectedDate.value = new Date(year, month, day);
      formattedDate.value = format(selectedDate.value, 'yyyy-MM-dd');
      emit('change', formattedDate.value);
    });

    watch(
      () => props.customValidity,
      curr => {
        if (yearSelect.value) {
          yearSelect.value.setCustomValidity(curr);
        }
      },
    );

    return {
      componentEl,
      yearSelect,
      month,
      months,
      day,
      days,
      year,
      years,
    };
  },
});
