

























import { Alert } from '@conversa/bedazzled/src/alerts';
import {
  Dialog,
  DialogActions,
  DIALOG_PROPS,
} from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { Alert, Dialog, DialogActions },
  props: ['errorMessage', ...DIALOG_PROPS],
  emits: ['resend', 'dialog-closed'],
  setup(_, ctx) {
    return {
      dialogText: {
        title: 'Resend Chat Link?',
        description:
          'Would you like to send a chat link to this patient? The notification will be sent immediately based on the preferences in the patient profile',
        resendButton: 'Resend Link',
        cancelButton: 'Cancel',
      },
      resend: () => ctx.emit('resend'),
      cancel: () => ctx.emit('dialog-closed'),
    };
  },
});
