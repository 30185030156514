





























import { computed, defineComponent } from '@vue/composition-api';

interface Option {
  value: string;
  content: string;
}

export default defineComponent<{
  multiple?: boolean;
  required?: boolean;
  id: string;
  inputValue: (string | number)[] | string;
  name: string;
  options: Option[];
  placeholder: string;
}>({
  props: [
    'id',
    'inputValue',
    'legend',
    'multiple',
    'name',
    'options',
    'placeholder',
    'required',
  ],
  setup(props, { emit }) {
    const size = computed(() => {
      if (!props.multiple) return 0;
      return props.options.length > 5 ? 5 : props.options.length;
    });

    const shouldEmitNumeric = () => {
      if (props?.options?.length) {
        return typeof props.options[0]?.value === 'number';
      }

      return false;
    };

    const castValue = stringValue => {
      if (shouldEmitNumeric()) {
        return parseInt(stringValue, 10);
      }

      return stringValue;
    };

    return {
      size,
      selected(value) {
        const isSelectedForMultiple =
          props.multiple &&
          Array.isArray(props.inputValue) &&
          props.inputValue.includes(value);
        const isSelectedForSingle = value === props.inputValue;
        return isSelectedForMultiple || isSelectedForSingle;
      },
      // eslint-disable-next-line
      onChange(event: any) {
        emit(
          'change',
          !props.multiple
            ? castValue(event.target.value)
            : [...event.target.selectedOptions].map(({ value }) =>
                castValue(value),
              ),
        );
      },
    };
  },
});
