
























































































































































import { SelectInput, RadioInput } from '../inputs';
import { computed, defineComponent } from '@vue/composition-api';
import TableHeader from './table-header.interface';
import { PropType } from 'vue';
export default defineComponent({
  components: { SelectInput, RadioInput },
  props: {
    headers: { type: Array as PropType<TableHeader[]> },
    data: Array,
    link: Boolean,
    expandable: Boolean,
    useHeaders: { type: Boolean, default: true },
    sortCol: String,
    sortDir: String,
    pagination: { type: Boolean, default: true },
    maxItems: Number,
    tableRows: {
      type: Number,
      default: 10,
      validator: function(value) {
        // The value must be greater than 0
        return value > 0;
      },
    },
    expandedRows: Array,
    page: Number,
  },
  setup(props, { emit }) {
    return {
      modRows: (i, row) => {
        emit('rowExpanded', { row, index: i });
      },
      updateSort: col => {
        emit('sortChanged', {
          col,
          dir:
            (props.sortCol === col && props.sortDir === 'desc' && 'asc') ||
            'desc',
        });
      },
      updatePage: page => {
        emit('updatePage', page);
      },
      headersSkipFirst: computed(() => props.headers.slice(1)),
      selectOptions: props.headers.reduce((acc, header) => {
        if (header.sortable) {
          acc.push({ value: header.value, content: header.content });
        }
        return acc;
      }, []),
      paginationData: computed(() => {
        return {
          min: props.maxItems ? (props.page - 1) * props.tableRows + 1 : 0,
          max: Math.min(props.page * props.tableRows, props.maxItems),
          previousDisabled: props.tableRows <= 0 || props.page <= 1,
          nextDisabled:
            props.tableRows <= 0 ||
            props.page * props.tableRows >= props.maxItems,
        };
      }),
    };
  },
});
