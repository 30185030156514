



































import ActionCard from '@conversa/bedazzled/src/cards/action/ActionCard.vue';
import EmptyCase from '@/shared/components/EmptyCase.vue';
import { defineComponent } from '@vue/composition-api';
import EnrollmentRow from './EnrollmentRow.vue';

export default defineComponent({
  props: ['enrollments'],
  emits: ['new-enrollment', 'edit-enrollment', 'edit-status'],
  components: { ActionCard, EnrollmentRow, EmptyCase },
  setup() {
    return {};
  },
});
