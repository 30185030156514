




import AnnotationPlugin from 'chartjs-plugin-annotation';
import Chart from 'chart.js';
import 'chartjs-adapter-date-fns';
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: ['chartData', 'chartOptions', 'activeData'],
  setup(props) {
    const lineChart: Chart = ref('lineChart');

    Chart.plugins.register(AnnotationPlugin);

    watch(props.activeData, () => {
      if (!Object.keys(Chart.instances).length) {
        return new Chart(lineChart.value, {
          type: 'line',
          data: props.chartData,
          options: props.chartOptions,
        });
      }

      // Chart.js stores instances as object with integer as key
      // {
      //    0: { //first instance properties }
      //    1: { //second instance properties }
      // }
      // and iterates to new key value even when destroying old instance, so
      // we can't count on the key being 0 even though there's just one instance.
      // We have to destroy instances between patients because only creating a new one every time causes
      // a hover display bug that will show stale chart data

      const findInstance = Object.keys(Chart.instances)[0];
      const instance = Chart.instances[findInstance];
      if (instance) {
        instance.data = props.chartData;
        instance.options = props.chartOptions;
        instance.update();
      }
    });

    return {
      lineChart,
    };
  },
  destroyed() {
    if (Object.keys(Chart.instances).length)
      Chart.helpers.each(Chart.instances, instance => {
        instance.destroy();
      });
  },
});
