

































import sink from '@/sink';
import ResendChatDialog from './ResendChatDialog.vue';
import { defineComponent } from '@vue/composition-api';
import {
  PatientResendChatLinkRequested,
  ResendChatDialogDismissed,
} from './+state/events';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { InlineLoader, Alert } from '@/shared';
import { DialogInstance } from '@conversa/bedazzled/src/dialog/actions';

export default defineComponent({
  components: { ResendChatDialog, Alert, DialogTitle, InlineLoader },
  setup() {
    return {
      loading: sink.select('patients.detail.summary.sessions.isLoading'),
      errorMessage: sink.select('patients.detail.summary.sessions.error'),
      resend: sink.lazyBroadcast(PatientResendChatLinkRequested()),
      cancel: () => {
        sink.broadcast(ResendChatDialogDismissed());
        DialogInstance.close();
      },
    };
  },
});
