



































import CheckboxInput from '@conversa/bedazzled/src/inputs/CheckboxInput.vue';
import { ActionCard } from '@conversa/bedazzled/src/cards';
import { SimpleTable } from '@conversa/bedazzled/src/table';
import { defineComponent } from '@vue/composition-api';
import { TABLE_CONFIG } from '@conversa/bedazzled/src/constants/tableConfig';

interface Data {
  date: string;
  modules: string;
}

export default defineComponent<{
  data: Data[];
  pageNumber: number;
  count: number;
  canResendChat: boolean;
}>({
  props: ['data', 'pageNumber', 'count', 'canResendChat', 'viewHistoric'],
  emits: ['update-page', 'resend', 'view-historic-clicked'],
  components: { ActionCard, SimpleTable, CheckboxInput },
  setup(_, context) {
    return {
      headers: [
        {
          value: 'date',
          text: 'Date',
          sortable: false,
        },
        {
          value: 'modules',
          text: 'Program Modules',
          sortable: false,
        },
      ],
      tableRows: TABLE_CONFIG.scheduledChats.numRows,
      updatePage: page => context.emit('update-page', page),
      resend: () => context.emit('resend'),
      viewHistoricClicked: checked =>
        context.emit('view-historic-clicked', { checked }),
    };
  },
});
