



































import sink from '@/sink';
import { InlineLoader, Alert } from '@/shared';
import { defineComponent } from '@vue/composition-api';
import {
  CaregiverStatusChangeRequested,
  CaregiverDialogDismissed,
} from '../+state/events';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export default defineComponent({
  components: {
    Alert,
    InlineLoader,
    DialogTitle,
  },
  setup() {
    const { active } = sink.select('patients.detail.summary.caregiver').value;

    return {
      active,
      dialogText: sink.select('patients.details.summary.status-dialog-text'),
      loading: sink.select('patients.details.summary.caregiver-dialog.loading'),
      error: sink.select(
        'patients.details.summary.caregiver-dialog.error-message',
      ),
      cancel: () => {
        DialogInstance.close();
        sink.broadcast(CaregiverDialogDismissed());
      },
      updateStatus: sink.lazyBroadcast(
        CaregiverStatusChangeRequested({ active: !active }),
      ),
    };
  },
});
