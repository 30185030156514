















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    buttonAction: {
      type: Function,
    },
  },
});
