























































import sink from '@/sink';
import { InlineLoader, filterDateParse } from '@/shared';
import ChartingRender from './ChartingRender.vue';
import ActionCard from '@conversa/bedazzled/src/cards/action/ActionCard.vue';
import { defineComponent, onMounted } from '@vue/composition-api';
import { ChartFilterChanged, PatientChartingMounted } from './+state/events';
import { ChartDateFilters } from './models';
import DatePicker from '@/shared/components/DatePicker.vue';
import { addDays, format, subDays, subYears } from 'date-fns';

export default defineComponent({
  components: { InlineLoader, ChartingRender, DatePicker, ActionCard },
  setup() {
    onMounted(sink.lazyBroadcast(PatientChartingMounted()));

    const filters = sink.select('patients.detail.charting.chartDateFilters')
      .value;

    const i18nDateSetting = sink.select(
      'capabilities.international.datetimeFormat',
    ).value;

    const oneYearAgo = format(subYears(new Date(), 1), 'yyyy-MM-dd');
    const today = format(new Date(), 'yyyy-MM-dd');

    return {
      filterDateParse,
      i18nDateSetting,
      today,
      oneYearAgo,
      subDays,
      addDays,
      format,
      filters,
      isLoadingChart: sink.select('patients.detail.charting.isLoading').value,
      chartData: sink.select('patients.detail.charting.activePatientChartData'),
      chartOptions: sink.select(
        'patients.detail.charting.activePatientChartOptions',
      ),
      activeData: sink.select('patients.detail.charting.activePatientBpData'),
      onInputChanged(input: keyof ChartDateFilters, value) {
        if (
          (input === 'dateFrom' &&
            new Date(filterDateParse(value, i18nDateSetting)) <
              new Date(filterDateParse(filters.dateTo, i18nDateSetting))) ||
          (input === 'dateTo' &&
            new Date(filterDateParse(value, i18nDateSetting)) >
              new Date(filterDateParse(filters.dateFrom, i18nDateSetting)))
        )
          sink.broadcast(ChartFilterChanged({ input, value }));
      },
    };
  },
});
