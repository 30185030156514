



























import {
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watchEffect,
} from '@vue/composition-api';

export default defineComponent({
  props: [
    'name',
    'id',
    'placeholder',
    'inputValue',
    'required',
    'pattern',
    'type',
    'minlength',
    'maxlength',
    'disabled',
    'step',
  ],
  setup(props) {
    const active = ref(false);
    const labelClasses = reactive({ hover: false });

    watchEffect(() => {
      if (active.value || props.inputValue) {
        labelClasses.hover = true;
      } else {
        if (!props.inputValue) {
          labelClasses.hover = false;
        }
      }
    });

    onBeforeMount(() => {
      if (props.inputValue) {
        labelClasses.hover = true;
      }
    });

    return {
      inputType: props.type || 'text',
      labelClasses,
      active,
    };
  },
});
